import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/SiteLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`This website (or "book") is all about programming! The best way to learn about programming is to `}<em parentName="p">{`do`}</em>{` programming. Therefore, the main components of this book are `}<em parentName="p">{`code sandboxes`}</em>{` (think of them like a place to play with code).`}</p>
    <h2 {...{
      "id": "code-sandbox"
    }}>{`Code Sandbox`}</h2>
    <p>{`A lot of our learning in this book will revolve around us writing code to make the computer draw things for us. The best way for you to understand a piece of code is to tinker with it and see what happens when you change pieces! Therefore you will regularly see code "sandboxes" like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=first-sample-snippet",
        "live": "true",
        "id": "first-sample-snippet"
      }}>{`// Some drawing commands.
// More on them later.
$rect(100, 50, 0, 0, { fill: "lightblue" });
$text("Hello world!");

// Sending things to the "log"
$log("From the log!");
`}</code></pre>
    <p>{`Let's look at this whole "code sandbox" thing. We can break this down into a few pieces:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5cad27601d76a0444793789f7b0f1f55/b6e34/sandbox-breakdown.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Sandbox breakdown",
            "title": "Sandbox breakdown",
            "src": "/static/5cad27601d76a0444793789f7b0f1f55/00d43/sandbox-breakdown.png",
            "srcSet": ["/static/5cad27601d76a0444793789f7b0f1f55/63868/sandbox-breakdown.png 250w", "/static/5cad27601d76a0444793789f7b0f1f55/0b533/sandbox-breakdown.png 500w", "/static/5cad27601d76a0444793789f7b0f1f55/00d43/sandbox-breakdown.png 1000w", "/static/5cad27601d76a0444793789f7b0f1f55/b6e34/sandbox-breakdown.png 1103w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The pieces of this are:`}</p>
    <ul>
      <li parentName="ul">{`The "Code Editor", which is where we'll put our code.`}</li>
      <li parentName="ul">{`The "Log", which we can send data to from our code. This will be useful as we're playing with new ideas and want to do some quick testing.`}</li>
      <li parentName="ul">{`The "Stage", which is where we all of our drawing will happen.`}</li>
      <li parentName="ul">{`The "Run" button, which will run your code that's in the code editor. If you have changed the code from its default value, this button will also save your code so you can look at it later!`}</li>
      <li parentName="ul">{`The "Default" and "Yours" tabs, where you can toggle whether you are looking at your code or the default code for the given sandbox.`}</li>
    </ul>
    <h2 {...{
      "id": "the-log"
    }}>{`The "Log"`}</h2>
    <p>{`When programming, it's common to "log" things - or print them somewhere for us to inspect. We'll be doing something similar throughout this book! For example, suppose we told the computer to compute the value of `}<inlineCode parentName="p">{`13 * 87`}</inlineCode>{`. The computer would probably do that for us, but we also need to tell it to show us the value. Check out the sandbox below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=first-log-call",
        "live": "true",
        "id": "first-log-call"
      }}>{`// Change the line below to $log(13 * 87);
13 * 87;
`}</code></pre>
    <p>{`The piece `}<inlineCode parentName="p">{`13 * 87`}</inlineCode>{` doesn't actually show us anything! Your computer is computing the value, but it doesn't know that `}<em parentName="p">{`we want to know`}</em>{` the value. Changing that to: `}<inlineCode parentName="p">{`$log(13 * 87);`}</inlineCode>{` tells the computer to `}<em parentName="p">{`log`}</em>{` the value of `}<inlineCode parentName="p">{`13 * 87`}</inlineCode>{`. With that in place, you should see the value `}<inlineCode parentName="p">{`1131`}</inlineCode>{` showing up in the log.`}</p>
    <p>{`This `}<inlineCode parentName="p">{`$log`}</inlineCode>{` thing you keep seeing is a `}<em parentName="p">{`function`}</em>{`, and we're going to cover those in detail later on. For now, just know that if you want to log something - you can write `}<inlineCode parentName="p">{`$log("your thing here");`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "the-stage"
    }}>{`The "Stage"`}</h2>
    <p>{`Throughout this book we'll be telling our computer how to draw things. We'll be drawing things to a "stage". Don't worry too much about this word - it's just a word we chose. The "stage" is just the place where things will be drawn.`}</p>
    <p>{`We will be looking at some drawing commands that are available for us. These drawing things, if used correctly, will draw things on the stage. Here's an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=getting-started-stage",
        "live": "true",
        "id": "getting-started-stage"
      }}>{`$rect(120, 50);

$text("Hello world!");
`}</code></pre>
    <p>{`Can you figure out what the `}<inlineCode parentName="p">{`120`}</inlineCode>{` and `}<inlineCode parentName="p">{`50`}</inlineCode>{` in the code above mean? What happens if you change "Hello world" to something else?`}</p>
    <p>{`Now, here's a neat trick: if you click and drag on the edges of the stage, you can actually resize it! At first, we won't be using this a whole lot. But eventually we'll do some drawing that uses the size of the stagem. Here's a quick example of that. `}<strong parentName="p">{`Don't worry if the code doesn't make any sense!`}</strong>{` Just resize the stage and re-run the code to see what's happening.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=getting-started-resize",
        "live": "true",
        "id": "getting-started-resize"
      }}>{`// Create circle
const r = 25;
const c = $circle(r, r, r);

// To the right-top corner
$animate({ item: c, x: $stageWidth - r, duration: 0.5 });
// Then down
$animate({ item: c, y: $stageHeight - r, duration: 1 });
// Then to start
$animate({ item: c, x: $stageWidth/2, y: $stageHeight/2, duration: 0.5, radius: 2*r, fill: "blue" });
`}</code></pre>
    <h2 {...{
      "id": "code-errors"
    }}>{`Code errors`}</h2>
    <p>{`Programmers make mistakes. Like, `}<em parentName="p">{`a lot`}</em>{`. But it's 👌, because we're just humans - and making mistakes is part of being a human.`}</p>
    <p>{`If you make a mistake in a sandbox, and the computer doesn't know how to make sense of your code, you'll see an error with a message from the computer:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=getting-started-error",
        "live": "true",
        "id": "getting-started-error"
      }}>{`$log(COMPUTER_NO_LIKEY);
`}</code></pre>
    <p>{`This will give you some indication of what you did wrong, and how you might fix it! Get used to seeing this screen - even the best of programmers make mistakes regularly while writing code! It's part of the process 👍.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      